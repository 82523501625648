import React from 'react'
import 'chart.js/auto'
import {Bar, Radar} from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'

const Result = props => {

    const calcAvg = arr => arr.reduce((a, b) => a + b, 0) / arr.length
    const amp = ['Toughness', 'Resilience', 'Flexibility'].map(i => {
        const raw = props.hasil.filter(row => row.kluster === i).map(row => parseFloat(row.nilai))
        return parseFloat(calcAvg(raw).toFixed(2))
    })

    const options = {
        maintainAspectRatio: false,
        scales: {
          x: {
            grid: {
              display: false
            }
          },
          y: {
            display: true,
            grid: {
              display: true
            },
            suggestedMin: 0,
            suggestedMax: 5
          }
        },
        plugins: {
          legend: {
            display: false
          },
          datalabels: {
            display: true,
            color: "black"
          }
        }
    }

    const optionsRadar = {
        maintainAspectRatio: true,
        scales: {
          x: {
            display: false,
            grid: {
              display: false
            }
          },
          y: {
            display: false,
            grid: {
              display: false
            }
          },
          r: {
            ticks:{
              display: false
            },
            suggestedMin: 0,
            suggestedMax: 5
          }
        },
        plugins: {
          legend: {
            display: false
          },
          datalabels: {
            display: true,
            color: "black"
          }
        }
    }

    const data = {
        labels: ['Toughness', 'Resilience', 'Flexibility'],
        datasets: [
            {
                data: amp,
                label: 'Adversary Mental Profile',
                backgroundColor: [
                  'rgba(255, 109, 1, 0.2)',
                  'rgba(56, 118, 29, 0.2)',
                  'rgba(17, 85, 204, 0.2)'
                ],
                borderColor: [
                  'rgb(255, 109, 1)',
                  'rgb(56, 118, 29)',
                  'rgb(17, 85, 204)'
                ],
                borderWidth: 1
            }
        ]
    }

    const generateDataChart = (kluster, i, tipe = null) => {
        const bgColorMap = [
          'rgba(255, 109, 1, 0.2)',
          'rgba(56, 118, 29, 0.2)',
          'rgba(17, 85, 204, 0.2)'
        ]

        const borderColor = [
          'rgb(255, 109, 1)',
          'rgb(56, 118, 29)',
          'rgb(17, 85, 204)'
        ]

        return {
            labels: props.hasil.filter(row => row.kluster === kluster).map(row => row.subkluster),
            datasets: [
                {
                    data: props.hasil.filter(row => row.kluster === kluster).map(row => parseFloat(row.nilai)),
                    label: kluster,
                    backgroundColor: bgColorMap[i],
                    borderColor: borderColor[i],
                    borderWidth: 1
                }
            ]
        }
    }

    return(
        <div className='result-container'>
            <div className='amp-section'>
                <h3 style={{'textAlign': 'center'}}>Nilai AMP sebesar <strong style={{color: '#7E146B'}}>{(amp.reduce((a, b) => a + b, 0)/3).toFixed(2)}</strong></h3>
                <div className='klaster'>
                    <Bar data={data} plugins={[ChartDataLabels]} options={options}/>
                </div>
            </div>
            <div className='klaster-section'>
              <div>
                <h4 style={{'textAlign': 'center'}}>Toughness</h4>
                <Radar data={generateDataChart('Toughness', 0)} plugins={[ChartDataLabels]} options={optionsRadar}/>
              </div>
              <div>
                <h4 style={{'textAlign': 'center'}}>Resilience</h4>
                <Radar data={generateDataChart('Resilience', 1)} plugins={[ChartDataLabels]} options={optionsRadar}/>  
              </div>
              <div>
                <h4 style={{'textAlign': 'center'}}>Flexibility</h4>
                <Radar data={generateDataChart('Flexibility', 2)} plugins={[ChartDataLabels]} options={optionsRadar}/>
              </div>
            </div>
        </div>
    )
}

export default Result