import React, {useState, useEffect} from 'react'
import ButtonQ from './ButtonQ'
import IdentityForm from './IdentityForm'
import Result from './Result'
import ApiService from '../apiService/ApiService'
import './button.css'

const QuestionItems = props => {
    const [questions, setQuestions] = useState()
    const [isLoading, setLoading] = useState(true)
    const [timeCounter, setTimeCounter] = useState()
    const [finalPage, setFinalPage] = useState(false)
    const [personalInfo, setPersonalInfo] = useState()
    // const [responseAMP, setResponseAMP] = useState([{"subkluster":"Acceptance","kluster":"Resilience","nilai":"4.0000000000000000"},{"subkluster":"Bravery","kluster":"Toughness","nilai":"1.5000000000000000"},{"subkluster":"Challenge","kluster":"Toughness","nilai":"4.0000000000000000"},{"subkluster":"Commitment","kluster":"Toughness","nilai":"1.5000000000000000"},{"subkluster":"Concentration","kluster":"Toughness","nilai":"3.0000000000000000"},{"subkluster":"Confidence","kluster":"Toughness","nilai":"3.5000000000000000"},{"subkluster":"Control","kluster":"Resilience","nilai":"4.0000000000000000"},{"subkluster":"Defusion","kluster":"Flexibility","nilai":"2.0000000000000000"},{"subkluster":"Equanimity","kluster":"Resilience","nilai":"3.5000000000000000"},{"subkluster":"Managing Anxiety","kluster":"Resilience","nilai":"0.50000000000000000000"},{"subkluster":"Managing Emotion","kluster":"Resilience","nilai":"3.0000000000000000"},{"subkluster":"Meaningfulness","kluster":"Flexibility","nilai":"3.5000000000000000"},{"subkluster":"Motivation","kluster":"Toughness","nilai":"2.0000000000000000"},{"subkluster":"People Skill","kluster":"Flexibility","nilai":"2.5000000000000000"},{"subkluster":"Perseverance","kluster":"Resilience","nilai":"2.0000000000000000"},{"subkluster":"Present Moment","kluster":"Flexibility","nilai":"4.0000000000000000"},{"subkluster":"Self as Context","kluster":"Flexibility","nilai":"4.5000000000000000"},{"subkluster":"Self Reliance","kluster":"Resilience","nilai":"2.5000000000000000"},{"subkluster":"Values","kluster":"Flexibility","nilai":"3.0000000000000000"}])

    const [responseAMP, setResponseAMP] = useState()

    const handleClick = (no, response) => {
        let tempQustion = [...questions]
        tempQustion[no].response = response
        const timeNow = parseFloat(Date.now()/1000)
        tempQustion[no].timeToFill = (parseFloat(tempQustion[no].timeToFill) + parseFloat(timeNow - timeCounter)).toFixed(2)
        setQuestions(tempQustion)
        setTimeCounter(timeNow)
        console.log(questions)
    }

    const handleSubmit = () => {
        const nullItems = questions.findIndex(item => item.response === null)
        if(nullItems >= 0){
            const questionsItem = `${nullItems + 1}. ${questions[nullItems].item}`
            window.alert(`Pertanyaan belum dijawab: ${questionsItem}`)
        } else {
            setFinalPage(true)
        }
    }

    const handleSubmit2 = () => {
        const nullItems = personalInfo.findIndex(item => item.nilai === null)
        if(nullItems >= 0){
            const questionsItem = `${personalInfo[nullItems].namakolom}`
            window.alert(`Form belum diisi: ${questionsItem}`)
        } else {
            ApiService.postResult(personalInfo, questions, 1).then(ampResult => {
                setResponseAMP(ampResult.data)
            })
        }
    }

    const handleChangeColumn = (i, nilai) => {
        let tempPersonalInfo = [...personalInfo]
        tempPersonalInfo[i].nilai = nilai
        setPersonalInfo(tempPersonalInfo)
    }

    useEffect(() => {
        ApiService.getQuesionnaire().then(response => {
            if(response.code === 0){
                response.data.map(item => {
                    item['response'] = null
                    item['timeToFill'] = 0
                    return item
                })
                setQuestions(response.data)
                const timeNow = parseFloat(Date.now()/1000).toFixed(2)
                setTimeCounter(timeNow)
            } else console.log(response.message)
        }).catch(error => console.log(error))

        ApiService.getColumn(1).then(responseColumn => {
            if(responseColumn.code === 0){
                responseColumn.data.map(kolom => {
                    kolom['nilai'] = kolom.jeniskolom === 'option' ? kolom.opsi.split(',')[0] : null
                    return kolom
                })
                setPersonalInfo(responseColumn.data)
                setLoading(false)
            } else console.log(response.message)
        }).catch(error => console.log(error))
    }, [])

    return(
        isLoading === true || personalInfo === undefined ? 'Loading...' : finalPage === false ? <>
            {questions.map((item, i) => {
                return <ButtonQ key={i} no={i} response={item.response} item={`${i + 1}. ${item.item}`} handleClick={handleClick}/>
            })}
            <div style={{'display': 'grid', 'gridTemplateColumns': 'repeat(1, 1fr)', 'justifyItems': 'center', 'alignItems': 'center', 'width': '100%', 'textAlign': 'center'}}>
                <button className="btn-submit" onClick={() => handleSubmit()}>Submit</button>
            </div>
        </> : responseAMP === undefined ? <IdentityForm columns={personalInfo} handleChangeColumn={handleChangeColumn} handleSubmit={handleSubmit2}/> : <Result hasil={responseAMP}/>
    )
}

export default QuestionItems