import React from 'react'

const ButtonQ = props => {
    return(
        <div className='item-container'>
            <div className='q-text-item'>{props.item}</div>
            <div className='q-container'>
                <div className='btn-container'>
                    <button className={props.response === 1 ? 'q-item min-2 active' : 'q-item min-2'} onClick={() => props.handleClick(props.no, 1)}></button>
                </div>
                <div className='btn-container'>
                    <button className={props.response === 2 ? 'q-item min-1 active' : 'q-item min-1'} onClick={() => props.handleClick(props.no, 2)}></button>
                </div>
                <div className='btn-container'>
                    <button className={props.response === 3 ? 'q-item netral active' : 'q-item netral'} onClick={() => props.handleClick(props.no, 3)}></button>
                </div>
                <div className='btn-container'>
                    <button className={props.response === 4 ? 'q-item plus-1 active' : 'q-item plus-1'} onClick={() => props.handleClick(props.no, 4)}></button>
                </div>
                <div className='btn-container'>
                    <button className={props.response === 5 ? 'q-item plus-2 active' : 'q-item plus-2'} onClick={() => props.handleClick(props.no, 5)}></button>
                </div>
                <div className='q-text'>
                    Sangat Tidak Sesuai
                </div>
                <div className='q-text'>
                    Tidak Sesuai
                </div>
                <div className='q-text'>
                    Netral
                </div>
                <div className='q-text'>
                    Sesuai
                </div>
                <div className='q-text'>
                    Sangat Sesuai
                </div>
            </div>
        </div>
    )
}

export default ButtonQ