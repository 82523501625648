import React, { Component } from 'react'
import Header3 from '../layout/header/header3'
import Herosection2 from '../widgets/herosection/herosection2'
import Team1 from '../widgets/team/team1'
import FeatureServices from '../widgets/featurebox/services'
import Services from '../widgets/index/services'
class Trf extends Component {
  constructor(props)
  {
      super(props)
  }
  componentDidMount() {
      window.scrollTo(0, 0)
  }
    render() {
        return (
            <div>
            {/*header start*/}
            <Header3 />
            {/*header end*/}
            {/*hero section start*/}
            <section className="custom-pt-1 custom-pb-3 bg-primary position-relative parallaxie" data-bg-img={require(`../assets/images/bg/03.png`)}>
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-12 col-lg-5 col-xl-5 mr-auto mb-8 mb-lg-0">
                    {/* Image */}
                    <img src={require(`../assets/images/hero/02.png`)} className="img-fluid" alt="..." />
                  </div>
                  <div className="col-12 col-lg-7 col-xl-6">
                    {/* Heading */}
                    <h1 className="display-4 text-white">
                      Jadilah <span className="font-weight-bold">Pemenang</span>
                    </h1>
                    {/* Text */}
                    <p className="lead text-light text-white">Adversary Mental Profile atau Profil Mental Pemenang membantu individu dan organisasi memahami kondisi mentalnya &amp; mendorong mental yang lebih baik sehingga dapat menjadi pemenang</p>
                    {/* Buttons */} <Link to="/assess" className="btn btn-outline-light mr-1">
                      Free Cek Profil Mental Saya
                        </Link>
                  </div>
                </div>
                {/* / .row */}
              </div>
              {/* / .container */}
              <div className="shape-1 bottom" style={{ height: '250px', overflow: 'hidden' }}>
                <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                  <path d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z" style={{ stroke: 'none', fill: '#fff' }} />
                </svg>
              </div>
            </section>
          </div>
        )
    }
}

export default Trf