import React, { Component } from 'react'
import ModalLayanan from '../../customPages/ModalLayanan'
import '../../customPages/service.css'

class Services extends Component {
    constructor(props)
    {
        super(props)
        this.state = {
            is_modal_open: false,
            jenis_layanan: 'Layanan Organisasi'
        }
        this.set_is_modal_open = this.set_is_modal_open.bind(this)
        this.set_layanan = this.set_layanan.bind(this)
    }
    set_is_modal_open(new_state) {
        this.setState({
            is_modal_open: new_state
        })
    }
    set_layanan(jenis_layanan){
        this.setState({
            jenis_layanan: jenis_layanan
        })
    }

    handleClick = p => {
        this.set_layanan(p)
        this.set_is_modal_open(true)
    }

    render() {
        return (
            <section className="custom-pt-1 custom-pb-2 bg-dark position-relative" data-bg-img={require(`../../assets/images/bg/02.png`)}>
                    <ModalLayanan
                        is_modal_open = {this.state.is_modal_open}
                        jenis_layanan = {this.state.jenis_layanan}
                        set_is_modal_open = {this.set_is_modal_open}
                    />
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-lg-4 col-md-6 mb-8 mb-lg-0 text-white">
                                <div> <span className="badge badge-primary-soft p-2">
                                    <i className="la la-cubes ic-3x rotation" />
                                </span>
                                <h2 className="mt-3 mb-0">Menjadi pemenang dengan Adversary Mental Profile</h2>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6" onClick={() => this.handleClick('Layanan Individu')} id='pt'>
                                <div className="bg-primary-soft rounded p-5">
                                <div className="d-flex align-items-center mb-4">
                                    <div className="mr-3">
                                    <img className="img-fluid" src={require(`../../assets/images/icon/01.svg`)} alt="" />
                                    </div>
                                    <h5 className="m-0 text-light">Layanan Individu</h5>
                                </div>
                                <p className="mb-0"></p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 mt-6 mt-md-0" onClick={() => this.handleClick('Layanan Organisasi')} id='pt'>
                                <div className="bg-primary-soft rounded p-5">
                                <div className="d-flex align-items-center mb-4">
                                    <div className="mr-3">
                                    <img className="img-fluid" src={require(`../../assets/images/icon/02.svg`)} alt="" />
                                    </div>
                                    <h5 className="m-0 text-light">Layanan Organisasi</h5>
                                </div>
                                <p className="mb-0"></p>
                                </div>
                            </div>
                            <a href="https://wa.me/6281382611395" className="col-lg-4 col-md-6 mt-6 mt-md-0" id='pt'>
                                <div className="rounded p-5">
                                <div className="d-flex align-items-center mb-4">
                                    <div className="mr-3">
                                    <img className="img-fluid" src={require(`../../assets/images/icon/whatsapp.svg`)} alt="" />
                                    </div>
                                    <h5 className="m-0 text-light">Hubungi Kami</h5>
                                </div>
                                <p className="mb-0"></p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="shape-1" style={{height: '150px', overflow: 'hidden'}}>
                        <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{height: '100%', width: '100%'}}>
                        <path d="M0.00,49.98 C150.00,150.00 271.49,-50.00 500.00,49.98 L500.00,0.00 L0.00,0.00 Z" style={{stroke: 'none', fill: '#fff'}} />
                        </svg>
                    </div>
                    <div className="shape-1 bottom" style={{height: '200px', overflow: 'hidden'}}>
                        <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{height: '100%', width: '100%'}}>
                        <path d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z" style={{stroke: 'none', fill: '#fff'}} />
                        </svg>
                    </div>
                    </section>
                   
        );
    }
}

export default Services;