import React from 'react'

const IdentityForm = props => {

    const generateInput = (j, nama, tipe, option = null) => {
        return tipe === 'option' ? <div className='inputForm'><label>{nama}</label><select onChange={e => props.handleChangeColumn(j, e.target.value)}>
            {option.split(',').map(opsi => {
                return <option value={`'${opsi}'`}>{opsi}</option>
            })}
        </select></div> : <div className='inputForm'><label>{nama}</label><input type={tipe} required onChange={e => props.handleChangeColumn(j, e.target.value)}/></div>
    }

    return(
        <div className='item-container'>
            <div className='finalForm'>
                <h4 style={{'textAlign': 'center'}}>Isi identitas diri sebelum melihat hasil</h4>
                {props.columns.map((isian, i) => {
                    return generateInput(i, isian.namakolom, isian.jeniskolom, isian.opsi)
                })}
            </div>
            <div style={{'display': 'grid', 'gridTemplateColumns': 'repeat(1, 1fr)', 'justifyItems': 'center', 'alignItems': 'center', 'width': '100%', 'textAlign': 'center'}}>
                <button className="btn-submit" onClick={() => props.handleSubmit()}>Submit</button>
            </div>
        </div>
    )
}

export default IdentityForm