import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import ModalPerson from '../../customPages/ModalPerson'

class Team1 extends Component {
  constructor(props)
    {
        super(props)
        this.state = {
            is_modal_open: false,
            person: 'i'
        }
        this.set_is_modal_open = this.set_is_modal_open.bind(this)
        this.set_person = this.set_person.bind(this)
    }
    set_is_modal_open(new_state) {
        this.setState({
            is_modal_open: new_state
        })
    }
    set_person(person){
        this.setState({
          person: person
        })
    }

    handleClick = p => {
        this.set_person(p)
        this.set_is_modal_open(true)
    }

    render() {
        return (
            <div className="row">
            <ModalPerson
                      is_modal_open = {this.state.is_modal_open}
                      person = {this.state.person}
                      set_is_modal_open = {this.set_is_modal_open}
                  />
            <div className="col-12 col-lg-3 col-md-6 mb-8 mb-lg-0">
              <div className="text-center shadow hover-translate p-5">
                <div className="mb-3">
                  <img className="img-fluid rounded-top" src="https://media-exp1.licdn.com/dms/image/C5603AQGbwl4J9kdEfQ/profile-displayphoto-shrink_200_200/0/1632426870692?e=1658966400&v=beta&t=Gqno1ZWEeTkzD7rO2OYcv27-QWjwcz8bUU64p7Wx6ac" alt="" />
                </div>
                <div>
                  <h6 className="mb-1">Drs.T. Irwan Amrun, M.Psi., Psikolog</h6>
                  <small className="text-muted mb-3 d-block">Founder Adversary Mental Profile</small>
                  <Link onClick={() => this.handleClick('i')} className="btn-link">Read Details</Link>
                  {/* <ul className="list-inline mb-0">
                    <li className="list-inline-item"><Link className="text-muted" to="/"><i className="la la-facebook h4" /></Link>
                    </li>
                    <li className="list-inline-item"><Link className="text-muted h6" to="/"><i className="la la-dribbble h4" /></Link>
                    </li>
                    <li className="list-inline-item"><Link className="text-muted h6" to="/"><i className="la la-twitter h4" /></Link>
                    </li>
                    <li className="list-inline-item"><Link className="text-muted h6" to="/"><i className="la la-linkedin h4" /></Link>
                    </li>
                  </ul> */}
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-3 col-md-6 mb-8 mb-lg-0">
              <div className="text-center shadow hover-translate p-5">
                <div className="mb-3">
                  <img className="img-fluid rounded-top" src="https://media-exp1.licdn.com/dms/image/C4D03AQFedltXNVh0Mw/profile-displayphoto-shrink_200_200/0/1517393785530?e=1658966400&v=beta&t=Q3iA-lZbf295NLaiiyRehx0oRmdItqulia1tUL-jV5U" alt="" />
                </div>
                <div>
                  <h6 className="mb-1">Urip Purwono., M.Sc., M.S., PhD</h6>
                  <small className="text-muted mb-3 d-block">
                    Senior Lecturer and Researcher at Faculty of Psychology, Universitas Padjajaran
                  </small>
                  <Link onClick={() => this.handleClick('u')} className="btn-link">Read Details</Link>
                  {/* <ul className="list-inline mb-0">
                    <li className="list-inline-item"><Link className="text-muted" to="/"><i className="la la-facebook h4" /></Link>
                    </li>
                    <li className="list-inline-item"><Link className="text-muted h6" to="/"><i className="la la-dribbble h4" /></Link>
                    </li>
                    <li className="list-inline-item"><Link className="text-muted h6" to="/"><i className="la la-twitter h4" /></Link>
                    </li>
                    <li className="list-inline-item"><Link className="text-muted h6" to="/"><i className="la la-linkedin h4" /></Link>
                    </li>
                  </ul> */}
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-3 col-md-6 mb-8 mb-md-0">
              <div className="text-center shadow hover-translate p-5">
                <div className="mb-3">
                  <img className="img-fluid rounded-top" src={require(`../../assets/images/team/sandra.png`)} alt="" />
                </div>
                <div>
                  <h6 className="mb-1">Sandra C. C. Siahaan, S.Psi, Psikolog</h6>
                  <small className="text-muted mb-3 d-block">Business Development &amp; Operation</small>
                  <Link onClick={() => this.handleClick('s')} className="btn-link">Read Details</Link>
                  {/* <ul className="list-inline mb-0">
                    <li className="list-inline-item"><Link className="text-muted" to="/"><i className="la la-facebook h4" /></Link>
                    </li>
                    <li className="list-inline-item"><Link className="text-muted h6" to="/"><i className="la la-dribbble h4" /></Link>
                    </li>
                    <li className="list-inline-item"><Link className="text-muted h6" to="/"><i className="la la-twitter h4" /></Link>
                    </li>
                    <li className="list-inline-item"><Link className="text-muted h6" to="/"><i className="la la-linkedin h4" /></Link>
                    </li>
                  </ul> */}
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-3 col-md-6">
              <div className="text-center shadow hover-translate p-5">
                <div className="mb-3">
                  <img className="img-fluid rounded-top" src="https://media-exp1.licdn.com/dms/image/C5603AQGJyIKV2rrzTA/profile-displayphoto-shrink_200_200/0/1635209283549?e=1658966400&v=beta&t=04VEJxr-daiEFj7wqITevb8CuZsnEgNs8OjZ3Sl1QxM" alt="" />
                </div>
                <div>
                  <h6 className="mb-1">Febryan Hikmat</h6>
                  <small className="text-muted mb-3 d-block">Data and Analytics</small>
                  <Link onClick={() => this.handleClick('f')} className="btn-link">Read Details</Link>
                  {/* <ul className="list-inline mb-0">
                    <li className="list-inline-item"><Link className="text-muted" to="/"><i className="la la-facebook h4" /></Link>
                    </li>
                    <li className="list-inline-item"><Link className="text-muted h6" to="/"><i className="la la-dribbble h4" /></Link>
                    </li>
                    <li className="list-inline-item"><Link className="text-muted h6" to="/"><i className="la la-twitter h4" /></Link>
                    </li>
                    <li className="list-inline-item"><Link className="text-muted h6" to="/"><i className="la la-linkedin h4" /></Link>
                    </li>
                  </ul> */}
                </div>
              </div>
            </div>
          </div>
        
        );
    }
}

export default Team1;