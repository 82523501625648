import React from "react"
import { Modal, ModalHeader, ModalBody } from "reactstrap"
import './layanan.css'

class ModalLayanan extends React.Component{
    constructor(props) {
        super(props)
    }

    individu = <>
        <p>Adversary mental profile (AMP) memadukan antara parameter-parameter dari berbagai metode pengukuran ketangguhan mental, ketahanan mental, dan fleksibilitas mental yang ada, dan mengembangkannya dalam suatu bentuk konsep baru yang disusun berdasarkan model yang dikembangkan oleh kami. Model ini memetakan ulang parameter-parameter yang menyusun tiap-tiap komponen ketangguhan, ketahanan, dan fleksibilitas mental secara terpadu ke dalam bentuk konsep dasar Adversary Mental Profile. Layani Individu dilakukan memalui self assessment yang akan mengukur:</p>
        <ol>
            <li>
                <strong>KETANGGUHAN</strong>. Kemampuan mental dalam melakukan kerja secara aktif, yang memandang adversary sebagai sesuatu yang harus diatasi, dapat berupa persoalan, kesulitan, hambatan, rintangan, perubahan, atau ancaman. Mencakup Tantangan (Kesungguhan dalam melaksanakan rencana/pemikiran), Komitmen (Keteguhan atlet terhadap tujuan  atau tugas kerja), Konsentrasi (Kemampuan untuk menempatkan / menfokuskan pikiran, perhatian, perasaan, dan tenaga pada satu tujuan, sehingga tidak mudah terpengaruh oleh stimuli eksternal maupun internal), Keberanian (Tingkat keyakinan untuk melakukan sesuatu Tingkat keyakinan untuk melakukan sesuatu), Kepercayaan diri (Tingkat keyakinan atas kemampuan diri sendiri), dan Motivasi (Kemampuan memelihara dan mempertahankan tingkat motivasi diri)
            </li>
            <li>
                <strong>KETAHANAN</strong>. Kemampuan mental dalam mempertahankan suatu kinerja dalam situasi yang berbeda-beda, merespons adversary yang sudah masuk dalam kategori ancaman terhadap kedudukan mental, yang dapat berupa perubahan, kesulitan, rintangan, atau hambatan. Mencakup Penerimaan (Kemampuan untuk menerima pengalaman yang ingin dihindari), Kontrol (Ukuran sejauhmana seseorang merasakan dirinya berada pada dalam kendali kehidupan mereka), Equanimity (Ketenangan jiwa dalam menghadapi tekanan), Pengelolaan Kecemasan (Kemampuan dalam mengelola rasa cemas, takut, gugup, panik, dan marah, sehingga menjadi energi positif bagi diri), Managing Emotion - Pengelolaan Emosi (Kemampuan dalam mengenali, mengelola, dan mengendalikan emosi dan menjadikannya kekuatan), Self Reliance (Kemampuan mempercayai diri sendiri dan orang lain), dan Perseverence (Ketekunan untuk berjuang dalam kesulitan)
            </li>
            <li>
                <strong>FLEKSIBILITAS</strong>. Kemampuan mental untuk melakukan penyesuaian terhadap berbagai perubahan situasi, yang bekerja secara aktif untuk memberikan alternatif-alternatif jalan penyelesaian masalah dalam wujud Kemampuan analisis, strategi, dan kreativitas, maupun bekerja secara reaktif untuk merespon adversary yang dihadapi dengan kelenturan atau toleransi. Mencakup Defusion  (Kemampuan mengendalikan pikiran sehingga tidak terlalu kuat mempengaruhi suatu persepsi), People Skill (Kemampuan dalam bersosialisasi dengan orang lain dan memelihara relasi), Values-Menghargai (Kemampuan untuk menghargai sesuatu), Meaningfulness (Kesadaran akan makna hidup diri sendiri dan orang lain), Present Moment  (Kemampuan menyadari keadaan saat ini sehingga terjadi kontak psikis yang lebih kuat dan lebih nyata), dan Self as Context (Kemampuan berpikir transenden yang menjadikan menyadari semua)
            </li>
        </ol>
        <p>
            Dengan mendapatkan Profil Mental Pemenang akan dapat dibuat langkah-langkah pengembangan diri yang efektif dan efisien.
        </p>
    </>

    organisasi = <>
        <ul>
            <li>
                <strong>Pemetaan Adversary Mental Profile (AMP) untuk organisasi</strong>
                <p>
                    Setiap organisasi membutuhkan mental adversary yang unggul dari segenap SDM-nya untuk bertahan dan terus berkembang. Dengan memiliki peta mental adversary setiap orang dalam organisasi dalam setiap klaster ketangguhan, ketahanan, dan fleksibilitas mental, organisasi dapat memanfaatkannya sebagai sarana untuk konsolidasi, dasar yang objektif dalam pengembangan sumber daya manusia. Keunggulan kualitas SDM dan kepemimpinan merupakan aspek penentu untuk kinerja organisasi dan tingkat kemajuannya. Dengan mengambil survey Adversary Mental Profile (AMP), pimpinan organisasi dapat menentukan kebijakan yang tepat terkait pengembangan SDM dan strategi organisasi secara umum. Survey ini dapat diimplementasikan oleh organisasi di sektor profit, non profit, dan juga sektor publik.
                </p>
            </li>
            <li>
                <strong>Training Pengembangan Adversary Mental Profile (AMP) untuk organisasi</strong>
                <p>
                    Berdasarkan hasil pemetaan Adversary Mental Profile (AMP) dalam sebuah organisasi dapat disusun program pengembangan yang tepat untuk karyawan baik secara umum maupun untuk karyawan pada level tertentu. Program pengembangan ini akan membantu perusahaan untuk memiliki SDM dengan profil mental pemenang, yang tangguh untuk mencapai kemajuan, memiliki ketahanan menghadapi situasi sulit dan fleksibel untuk merespon perubahan-perubahan yang terjadi dalam organisasi maupun kondisi dunia pada umumnya.
                </p>
            </li>
        </ul>
    </>

    render(){
        return (
            <Modal
                isOpen = {this.props.is_modal_open}
                modalClassName = 'modal-layanan'
            >
                <ModalHeader toggle = {() => this.props.set_is_modal_open(false)}>
                    {this.props.jenis_layanan}
                </ModalHeader>
                <ModalBody>
                    {this.props.jenis_layanan === 'Layanan Individu' ? this.individu : this.props.jenis_layanan === 'Layanan Organisasi' ? this.organisasi : ''}
                </ModalBody>
            </Modal>
        )
    }
}

export default ModalLayanan