import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ModalTrf from '../../customPages/ModalTrf';

class FeatureServices extends Component {
    constructor(props)
    {
        super(props)
        this.state = {
            is_modal_open: false,
            cluster: 't',
            cluster_title: 'Toughness – Ketangguhan'
        }
        this.set_is_modal_open = this.set_is_modal_open.bind(this)
        this.set_cluster = this.set_cluster.bind(this)
    }
    set_is_modal_open(new_state) {
        this.setState({
            is_modal_open: new_state
        })
    }
    set_cluster(param_cluster){
        this.setState({
            cluster: param_cluster,
            cluster_title: param_cluster === 't' ? 'Toughness – Ketangguhan' : param_cluster === 'r' ? 'Resilience – Ketahanan' : param_cluster === 'f' ? 'Flexibility – Fleksibilitas' : ''
        })
    }

    handleClick = p => {
        this.set_cluster(p)
        this.set_is_modal_open(true)
    }

    render() {
        return (
            <div className="row align-items-center">
                <ModalTrf
                    is_modal_open = {this.state.is_modal_open}
                    title = {this.state.cluster_title}
                    cluster = {this.state.cluster}
                    set_is_modal_open = {this.set_is_modal_open}
                />
                <div className="col-xl-4 col-lg-4 mb-8 mb-lg-0">
                    <div className={`px-4 py-7 rounded hover-translate text-center ${this.props.bgshadow}`}>
                        <div>
                            <img className="img-fluid" src={require(`../../assets/images/svg/01.svg`)} alt="" />
                        </div>
                        <h5 className="mt-4 mb-3">Toughness</h5>
                        <p>Tidak ada jalan mudah, karena semuanya memerlukan upaya sungguh-sungguh</p>
                        <Link onClick={() => this.handleClick('t')} className="btn-link">Read Details</Link>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-sm-6">
                    <div className={`px-4 py-7 rounded hover-translate text-center bg-white shadow`}>
                        <div>
                            <img className="img-fluid" src={require(`../../assets/images/svg/02.svg`)} alt="" />
                        </div>
                        <h5 className="mt-4 mb-3">Resilience</h5>
                        <p>Jangan khawatir berapa kali anda gagal, jika tetap mampu bangkit kembali dan bertahan</p>
                        <Link onClick={() => this.handleClick('r')} className="btn-link">Read Details</Link>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-sm-6 mt-6 mt-sm-0">
                    <div className={`px-4 py-7 rounded hover-translate text-center ${this.props.bgshadow}`}>
                        <div>
                            <img className="img-fluid" src={require(`../../assets/images/svg/03.svg`)} alt="" />
                        </div>
                        <h5 className="mt-4 mb-3">Flexibility</h5>
                        <p>Setiap orang memiliki caranya sendiri untuk dapat meraih keberhasilan</p>
                        <Link onClick={() => this.handleClick('f')} className="btn-link">Read Details</Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default FeatureServices;