import React, { Component, Fragment } from 'react'
import { HashRouter, Switch, Route ,withRouter} from 'react-router-dom'
import Header from './layout/header/header3'
import Footer from './layout/footer/footer'

import $ from 'jquery'

import './App.css'
import './Vendor.js'

import Maintenance from './pages/utilities/maintenance'
import ComingSoon from './pages/utilities/comingsoon'
import PageNotFound from './pages/utilities/404'
import  {getProducts} from './actions'
import { connect } from 'react-redux'
import Scrolltop from './layout/back-to-top'

import Home from './customPages/Home'
import QuestionItems from './customPages/QuestionItems'
import Trf from './customPages/Trf'

class App extends React.Component {
  constructor(props) {
    super(props)
  }
  UNSAFE_componentWillMount()
  {
    this.props.getProducts()
  }
  getUrl(pathname) {
    let pathArray = pathname.split('/')
    return `/${pathArray[1]}` === '/coming-soon' ? true : `/${pathArray[1]}` === '/maintenance' ? true : `/${pathArray[1]}` === '/page-not-found' ? true : false
  }
  setHeader(pathname) {
    let pathArray = pathname.split('/')
    return `/${pathArray[1]}` === '/index2' ? true : `/${pathArray[1]}` === '/index3' ? true : `/${pathArray[1]}` === '/index4' ? true : `/${pathArray[1]}` === '/index5' ? true : `/${pathArray[1]}` === '/index9' ? true : `/${pathArray[1]}` === '/home' ? true : `/${pathArray[1]}` === '/' ? true : `/${pathArray[1]}` === '/trf' ? true : false
 
  }
  render() {
    const { location } = this.props
    return (
      <Fragment>
         {
             this.getUrl(location.pathname) ?
                <Switch>
                    <Route path="/coming-soon" component={ComingSoon} />
                    <Route path="/maintenance" component={Maintenance} />
                    <Route path="/page-not-found" component={PageNotFound} />
                </Switch>
              :
        <div className="page-wrapper">
          {this.setHeader(location.pathname) ? null : <Header />}
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/home" component={Home} />
            <Route exact path="/assess" component={QuestionItems} />
            <Route exact path="/trf" component={Trf} />
          </Switch>
          <Footer />
          <Scrolltop />
        </div>
         }
      </Fragment>
    )
  }
}

const AppMapStateToProps = state => {
  return {
    products: state.data.products
  }
}

const AppMapDispatchToProps = dispatch => {
  return {
    getProducts: () => {
      dispatch(getProducts())
    }
  }
}


export default connect(AppMapStateToProps,AppMapDispatchToProps)(withRouter(App))

