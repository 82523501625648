import React from "react"
import { Modal, ModalHeader, ModalBody } from "reactstrap"
import './layanan.css'

class ModalPerson extends React.Component{
    constructor(props) {
        super(props)
    }

    i = <>
        <div className="mb-3">
            <img className="img-fluid rounded-top" src="https://media-exp1.licdn.com/dms/image/C5603AQGbwl4J9kdEfQ/profile-displayphoto-shrink_200_200/0/1632426870692?e=1658966400&v=beta&t=Gqno1ZWEeTkzD7rO2OYcv27-QWjwcz8bUU64p7Wx6ac" alt="" />
        </div>
        <p>
            Drs.T. Irwan Amrun, M.Psi., Psikolog adalah alumni Fakultas Psikologi Universitas Padjadjaran. Mengabdikan diri di lingkungan militer (TNI AD) dengan career Highlights :
        </p>
        <ol>
            <li>
                Perwira Khusus Psikologi Komando Pelaksana Operasi Timor Timur.
            </li>
            <li>
                Kepala Psikologi Akademi Militer.
            </li>
            <li>
                Di Dinas Psikologi TNI; Sahli Gol 4, Kasubdis Teknomil, Psi Sos, Litmat insani di Dispsiad.
            </li>
            <li>
                Di Dewan Ketahanan Nasional; Anjak bidang pendidikan dan pengembangan, Pembantu Deputi (Asdep) Bidang Hankam, dan  Pembantu Deputi (Asdep) sosial budaya di Setjen Dewan Ketahanan Nasional. Pensiun dengan pangkat terakhir Brigadir Jenderal TNI.
            </li>
            <li>
                Sejak bulan Januari 2015 – Januari 2020 menjadi Direktur Di LPTUI - Lembaga Psikologi Terapan UI. Bekerjasama dengan PT Intipesan Menyelenggarakan Seminar Nasional Kepemimpinan Berwawasan Kebangsaan Indonesia-KBKI - Januari 2020
            </li>
            <li>
                Sejak Agustus 2019 – Sekarang (Periode kedua di KONI – sebelumnya di Satgas Prima)  menjadi Wakil SportScience Koni Pusat di Bidang Psikologi (Narasumber Seminar Nasional di Bidang Sportscience &#38; Psikologi, Seminar dan lokakarya untuk pelatih, pengurus cabor dan atlet, Buku pedoman psikologi pendampingan atlet, penyusunan item inventory untuk mengukur kondisi mental atlet – digunakan beberapa cabor saat AG 2018)
            </li>
            <li>
                Sejak bulan September 2020 – sekarang menjadi Kasub Bid Mitigasi Satgas Covid 19 Nasional (Membentuk PORAKJU – Kelompok Penggerak Kemajuan di 9 Lokus Kodam)
            </li>
        </ol>
        <p>
        Memiliki sertifikasi internasional  Assessment Center dari PA Consulting.  Menjadi asesor di berbagai instansi,   antara lain Kemenkes, PT. Telkom, Pemda Jateng, dan Pemkot Bandung. Mendapat penugasan sebagai Direktur Operasi PLDC (Patriot Leadership Development Center) TNI AD.  Menjadi Trainer (Fasilitator) pada pelatihan Human Skill (Leadership, Team/synergic Building, Problem Solving, Achievement Motivation Training, dan lain-lain) di PT. Telkom, PT. Indosat, PT. POS, SKK Migas, PAMA Persada, United Tractor,   Menjadi psikolog di bidang olah raga sebagai pendamping petembak TNI - BISAM/TNI AD-AARM, Psikolog Persib 1995-1998, Psikolog PBSI, Pendampingan Atlet PGI (Golf) dalam AG 2018, Pendamping Psikologi Atlet Karate Gojukai dalam Kejuaran Dunia di Vancouver (2019). Menulis Buku Adversary Mental Profil (AMP) diterbitkan oleh percetakan UI tahun 2017 &#38; Milikilah Mental Pemenang diterbitkan Gramedia tahun 2019. Mengembangkan inventory AMP dan Model intervensi AMP untuk berbagai klaster (termasuk olahraga).
        </p>
    </>

    u = <>
        <div className="mb-3">
            <img className="img-fluid rounded-top" src="https://media-exp1.licdn.com/dms/image/C4D03AQFedltXNVh0Mw/profile-displayphoto-shrink_200_200/0/1517393785530?e=1658966400&v=beta&t=Q3iA-lZbf295NLaiiyRehx0oRmdItqulia1tUL-jV5U" alt="" />
        </div>
        <p>
            Fakultas Psikologi Universitas Padjajaran Pelaksana Operasi
        </p>
        <strong>EDUCATION</strong>
        <ul>
            <li>
                University of Massachusetts, Amherst, MA, USA, DOCTOR (Ph.D.) IN PSYCHOLOGY – PSYCHOMETRICS/ RESEARCH AND EVALUATION METHODS PROGRAM
            </li>
            <li>
                University of Massachusetts, Amherst, MA, USA, MASTER OF SCIENCE/ PSYCHOLOGY
            </li>
            <li>
                Indiana University - Bloomington, IN, USA, MASTER OF SCIENCE/ EDUCATION
            </li>
            <li>
                Universitas Padjadjaran, Bandung, Indonesia, DOCTORANDUS/ CLINICAL PSYCHOLOGY
            </li>
        </ul>
        <br></br>
        <strong>EXPERTISE</strong>
        <p>
            Psychometrics and Test Theories, Test Development, Monitoring and Evaluation, Quantitative Data Analysis
        </p>
        <br></br>
        <strong>CERTIFICATION </strong>
        <p>
            Institute for Rational Emotive Behavior Therapy/ Albert Ellis Institute, New York, NY, USA. PRIMARY CERTIFICATE OF RATIONAL EMOTIVE BEHAVIOR THERAPY
        </p>
        <br></br>
        <strong>PROFESSIONAL MEMBERSHIP</strong>
        <ul>
            <li>
                Indonesian Psychometrics Association, President
            </li>
            <li>
                Association of Regional Union of Psychological Society, President International Test Commission, Member &#38; International Advisory Committee American Psychological Association, International Affiliate
            </li>
            <li>
                American Educational Research Association, Member
            </li>
            <li>
                National Council of Measurement in Education, Member
            </li>
        </ul>
        <br></br>
        <strong>SPORT EXPERIENCE</strong>
        <ul>
            <li>
                Indonesia Sport Program. Di Sport and Exercise Science, Springfield College, Massachusetts, USA (2007)
            </li>
            <li>
                Test Development for Athlete KEMENPORA and KONI (2017-2018)
            </li>
            <li>
                Psychologist for Women Softball Team ASEAN GAMES 2018
            </li>
            <li>
                Observer for ASEAN PARA GAMES 2018
            </li>
        </ul>
    </>

    f = <>
        <div className="mb-3">
            <img className="img-fluid rounded-top" src="https://media-exp1.licdn.com/dms/image/C5603AQGJyIKV2rrzTA/profile-displayphoto-shrink_200_200/0/1635209283549?e=1658966400&v=beta&t=04VEJxr-daiEFj7wqITevb8CuZsnEgNs8OjZ3Sl1QxM" alt="" />
        </div>
        <p>
            Febryan Hikmat Suryaputra memiliki ketertarikan di bidang data dan teknologi. Terlibat aktif sebagai data analyst di platform Evermos dan produk Busana Muslim brand Shafira dan Zoya, serta menjadi co-founder Amatec Inter Sistem - startup yang bergerak di bidang digital enabler dan platform integration.
        </p>
    </>

    s = <>
        <div className="mb-3">
            <img className="img-fluid rounded-top" src={require(`../assets/images/team/sandra.png`)} alt="" />
        </div>
        <p>
            Lulusan Profesi Psikologi Universitas Gadjah Mada dan memiliki pengalaman lebih dari 15 tahun di bidang HR (rekrutmen, pengembangan SDM, penilaian kinerja, dan pengelolaan talenta) di perusahaan nasional Indonesia. Founder Assistant dalam pengembangan alat ukur Adversary Mental Profile (AMP).
        </p>
    </>

    render(){
        return (
            <Modal
                isOpen = {this.props.is_modal_open}
                modalClassName = 'modal-layanan'
            >
                <ModalHeader toggle = {() => this.props.set_is_modal_open(false)}>
                    {
                        this.props.person === 'i' ? 'Drs.T. Irwan Amrun, M.Psi., Psikolog' : this.props.person === 'u' ? 'Urip Purwono., M.Sc., M.S., PhD' : this.props.person === 's' ? 'Sandra Siahaan' : this.props.person === 'f' ? 'Febryan Hikmat' : ''
                    }
                </ModalHeader>
                <ModalBody>
                    {this.props.person === 'i' ? this.i : this.props.person === 'u' ? this.u : this.props.person === 'f' ? this.f : this.props.person === 's' ? this.s : ''}
                </ModalBody>
            </Modal>
        )
    }
}

export default ModalPerson