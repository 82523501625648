import React, { Component } from 'react'
import Header3 from '../layout/header/header3'
import Herosection2 from '../widgets/herosection/herosection2'
import Team1 from '../widgets/team/team1'
import FeatureServices from '../widgets/featurebox/services'
import Services from '../widgets/index/services'
class Home extends Component {
  constructor(props)
  {
      super(props)
  }
  componentDidMount() {
      window.scrollTo(0, 0)
  }
    render() {
        return (
            <div>
            {/*header start*/}
            <Header3 />
            {/*header end*/}
            {/*hero section start*/}
            <Herosection2 id="home"/>
            {/*hero section end*/} 
            {/*body content start*/}
            <div className="page-content">
              {/*client start*/}
              <section className="p-0">
                <div className="container">
                    <div className="row justify-content-center text-center">
                        <div className="col-12 col-md-12 col-lg-8 mb-8 mb-lg-0">
                                <p className="lead mb-0">Jika kita menghabiskan waktu untuk mencoba menjadi baik dalam segala hal, kita tidak akan pernah menjadi hebat dalam hal apapun.
                                <br></br>
                                Mereka yang berani mengambil resiko perubahan yang bisa merubah dirinya &amp; lingkungannya menjadi lebih baik.</p>
                        </div>
                    </div>
                </div>
              </section>

              <section id="mental-pemenang">
                  <div className="container">
                    <div className="row  text-center">
                      <div className="col">
                        <h1>Memahami Mental Pemenang</h1>
                        <p>Pada hakekatnya setiap Individu dilahirkan sebagai Pemenang,  namun untuk dapat dapat meraih predikat tersebut diperlukan ketangguhan, ketahanan dan fleksibilitas mental untuk mengatasi berbagai kendala, tantangan, dan ujian yang beragam (Adversary).  Setiap individu memiliki keunikan pribadi masing-masing dan juga akan tersedia Adversary tersendiri dalam menjalani kehidupannya. Adversary Mental Profile atau Profil Mental Pemenang merupakan aplikasi dari ilmu psikologi terapan yang dapat dijadikan rujukan pengembangan diri dan kalangan luas yang bergerak dalam bidang bisnis, olahraga, pendidikan, kesehatan, pengelola kebijakan publik, dan lain-lain.</p>
                      </div>
                    </div>
                    <FeatureServices bgshadow="bg-white shadow" />
                  </div>
              </section>

              <Services id="layanan"/>

              <section className="pt-0" id="tim-kami">
                  <div className="container">
                      <div className="row justify-content-center text-center">
                          <div className="col-12 col-md-12 col-lg-8 mb-8 mb-lg-0">
                              <div className="mb-8"> <span className="badge badge-primary-soft p-2 font-w-6">
                                  Adversary Mental Profile Team
                              </span>
                                  <h2 className="mt-3">Meet Our Team Of Expert</h2>
                                  {/* <p className="lead mb-0">All types of businesses need access to development resources, so we give you the option to decide how much you need to use.</p> */}
                              </div>
                          </div>
                      </div>
                      <Team1 />
                  </div>
              </section>

              <section id="mental-pemenang">
                  <div className="container">
                    <div className="row text-center">
                      <div className="col">
                        <h1>Dokumentasi</h1>
                      </div>
                      <div className="container">
                        <div className="text-center">
                          <iframe width="560" height="315" src="https://www.youtube.com/embed/TDkradvIxg0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                          <iframe width="560" height="315" src="https://www.youtube.com/embed/BCQefrBJRL8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                    </div>
                  </div>
                </div>
              </section>

              

            </div>
            {/*body content end*/}
          </div>
        )
    }
}

export default Home