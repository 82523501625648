import React from "react"
import { Modal, ModalHeader, ModalBody } from "reactstrap"

class ModalTrf extends React.Component{
    constructor(props) {
        super(props)
    }

    t = <>
        <p>Ketangguhan mental adalah kemampuan melakukan upaya pencapaian suatu tujuan yang diinginkan, kemampuan untuk menghadapi berbagai macam rintangan yang ada, dan kemampuan daya dobrak yang tinggi untuk memecahkan permasalahan atau adversary yang dihadapinya.</p>
        <p>Tema-tema yang berkaitan dengan ketangguhan mental:</p>
        <ul>
            <li>Daya juang</li>
            <li>Kemauan meningkatkan diri</li>
            <li>Kesungguhan dalam melaksanakan rencana/pemikiran</li>
            <li>Kesungguhan dalam meraih target</li>
            <li>Kepercayaan diri</li>
            <li>Kemauan untuk keluar dari zona nyaman</li>
            <li>Menganggap masalah sebagai tantangan</li>
            <li>Memandang kendali hidup pada diri sendiri</li>
            <li>Kemampuan mengendalikan emosi</li>
            <li>Kesadaran akan memberi lebih daripada meminta</li>
            <li>Keberlimpahan yang tinggi</li>
            <li>Kemampuan untuk berkonsentrasi</li>
            <li>Kemampuan melaksanakan melebihi target</li>
            <li>Kemampuan untuk mengadakan self talk positif</li>
            <li>Berani mengakui kesalahan</li>
            <li>Kemampuan mengendalikan diri</li>
            <li>Kemampuan Memelihara motivasi yang tinggi</li>
        </ul>
    </>

    r = <>
        <p>Ketahanan mental adalah sesuatu yang digambarkan ibarat pegas yang mampu menahan beban agar tidak jatuh atau rusak dan mempunyai daya pantul atas beban yang diterimanya. Perbedaannya dengan ketangguhan mental adalah bahwa ketangguhan mental bersifat aktif dan mempunyai arah dan perpindahan dalam mencapai target yang dituju, sedangkan ketahanan mental bersifat reaktif untuk menjaga agar mental tidak mengalami kondisi yang menurun atau mengalami ketidakstabilan ataupun kerusakan.</p>
        <p>Tema-tema yang tercakup dalam ketahanan mental individu meliputi:</p>
        <ul>
            <li>Kemampuan menjaga mood selalu positif</li>
            <li>Keyakinan atas kemampuan diri sendiri</li>
            <li>Kemampuan untuk mengadakan self talk yang positif agar tidak mengalami keterpurukan mental</li>
            <li>Kemampuan untuk berpikir lebih luas atas suatu permasalahan</li>
            <li>Ketabahan dalam menghadapi kesulitan; Kesadaran akan makna hidup</li>
            <li>Kesadaran bahwa manusia menghadapi masalah yang berbeda-beda</li>
            <li>Kemampuan menjaga diri dari ketidakstabilan mental</li>
            <li>Kemampuan menggunakan tekanan keadaan untuk mendorong ketangguhan mental</li>
        </ul>
    </>

    f = <>
        <p>Fleksibilitas mental adalah kemampuan mental dalam menerima perubahan-perubahan situasi yang dialami. Fleksibilitas dikaitkan dengan kemampuan menyesuaikan diri terhadap perubahan keadaan atau peran. Jika perubahan keadaan itu bersifat mengancam atau membawa kerusakan pada sistem yang bekerja secara normal, maka fleksibilitas mental akan sekaligus mempengaruhi ketahanan mental. Dan jika perubahan keadaan itu hanya bersifat perubahan peran atau target yang harus diselesaikan, maka fleksibilitas mental tersebut akan berpengaruh pada ketangguhan mental.</p>
        <p>Tema-tema yang berkaitan dengan fleksibilitas mental, yaitu:</p>
        <ul>
            <li>Kemampuan menerima pandangan orang lain secara terbuka</li>
            <li>Kemampuan untuk mengalami apa yang ingin dihindari</li>
            <li>Kemampuan untuk beradaptasi dengan lingkungan yang baru</li>
            <li>Kemampuan untuk memainkan peran yang berbeda-beda</li>
            <li>Menggunakan beberapa cara dalam menentukan pilihan</li>
            <li>Kemampuan berpindah-pindah jenis tugas atau target capaian tanpa kehilangan konsentrasi</li>
        </ul>
    </>

    render(){
        return (
            <Modal
                isOpen = {this.props.is_modal_open}
            >
                <ModalHeader toggle = {() => this.props.set_is_modal_open(false)}>
                    {this.props.title}
                </ModalHeader>
                <ModalBody>
                    {this.props.cluster === 't' ? this.t : this.props.cluster === 'r' ? this.r : this.props.cluster === 'f' ? this.f : ''}
                </ModalBody>
            </Modal>
        )
    }
}

export default ModalTrf