/**
 *  Main Menu Json
 */
export default [
   {
      "menu_title": "Beranda",
      "path": "/",
      "icon": "home",
      "id": "home"
   },
   // {
   //    "menu_title": "Mental Pemenang",
   //    "path": "/",
   //    "icon": "home",
   //    "id": "mental-pemenang"
   // },
   // {
   //    "menu_title": "Layanan",
   //    "path": "/",
   //    "icon": "home",
   //    "id": "layanan"
   // },
   // {
   //    "menu_title": "Tim Kami",
   //    "path": "/",
   //    "icon": "home",
   //    "id": "tim-kami"
   // },
   // {
   //    "menu_title": "Hubungi Kami",
   //    "path": "/",
   //    "icon": "home",
   //    "id": "hubungi-kami"
   // }
]
