import 'axios'
import axios from 'axios'
const token = 'Basic e3b0c44298fc1c149afbf4c8996fb92427ae41e4649b934ca495991b7852b855'
const host = 'https://mentalpemenang.com:81'

const ApiService = {
    getQuesionnaire: () => {
        return new Promise((resolve, reject) => {
            const config = {
                url: `${host}/questions`,
                method: 'get',
                headers: {
                    'Authorization': token
                }
            }
            axios(config).then(response => resolve(response.data)).catch(error => reject(error))
        })
    },
    getColumn: eventId => {
        return new Promise((resolve, reject) => {
            const config = {
                url: `${host}/columns`,
                method: 'get',
                headers: {
                    'Authorization': token
                },
                params: {
                    event_id: eventId
                }
            }
            axios(config).then(response => resolve(response.data)).catch(error => reject(error))
        })
    },
    postResult: (personal, jawaban, eventId) => {
        return new Promise((resolve, reject) => {
            const config = {
                url: `${host}/questions`,
                method: 'post',
                headers: {
                    'Authorization': token
                },
                data: {
                    personal: personal,
                    jawaban:jawaban,
                    event_id: eventId
                }
            }
            axios(config).then(response => resolve(response.data)).catch(error => reject(error))
        })
    }
}

export default ApiService